@import '@/_styles/import';
$smPackageCardMinHeight: 438px;
$mdPackageCardMinHeight: 448px;
$lgPackageCardMinHeight: 410px;

.PackageCard__media {
  position: relative;
  flex: 0 0 70%;
  width: 100%;
  min-height: $smPackageCardMinHeight;

  img {
    width: 100%;
    height: 100%;
  }

  .mediaImg {
    position: absolute;
    z-index: 1;

    overflow: hidden;

    width: 100%;
    height: 100%;

    filter: brightness(0.65);
    border-radius: 8px 0 0 8px;

    @media (max-width: $container-sm-max-width) {
      border-radius: 8px 8px 0 0;
    }
  }

  .mediaText {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    min-height: $smPackageCardMinHeight;
    padding: 40px 30px 30px;

    color: $white;

    .packageName {
      @include font($size: rem(30), $weight: 'bold', $color: $white, $line-height: rem(36));

      max-width: 33.125rem;
      margin-bottom: 0.625rem;

      @media (max-width: $container-sm-max-width) {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: 2rem;
      }

      @media (max-width: $container-lg1-max-width) {
        font-size: 1.5rem;
      }
    }

    .portsOfCalls {
      display: flex;
      flex-direction: row;
      max-width: 28.125rem;

      .portLocationIcon {
        .Icon svg {
          display: block;
          width: 1rem;
          height: 1.5rem;
        }

        @media (max-width: $container-sm-max-width) {
          display: none;
        }

        @media (max-width: $container-lg1-max-width) {
          font-size: 0.938rem;
        }
      }

      .ports {
        @include font($size: rem(17), $weight: 'medium', $color: $grey-light, $line-height: rem(24));

        margin-bottom: 24px;
      }

      @media (max-width: $container-sm-max-width) {
        padding: 0 1.5rem;

        .ports {
          font-size: 0.93rem;
          line-height: 1.5rem;
        }
      }
    }

    .shipNameIcon,
    .duration {
      @include font($size: rem(17), $color: $white, $line-height: rem(22));

      display: flex;
      flex-direction: row;
      height: 22px;
      margin-bottom: 8px;

      .shipIcon .Icon svg {
        position: relative;
        top: -0.125rem;

        display: block;

        width: 1rem;
        height: 1.5rem;
      }

      @media (max-width: $container-sm-max-width) {
        display: inline;

        .shipIcon {
          position: relative;
          top: rem(7);
        }
      }
    }

    .durationIcon {
      width: 1.5rem;
      height: 1.5rem;

      .Icon svg {
        position: relative;
        top: 0.125rem;

        display: block;

        height: 0.84rem;
        padding-left: 0.125rem;
      }

      @media (max-width: $container-sm-max-width) {
        position: relative;
        top: rem(7);
      }
    }

    .shipNameDuration {
      display: flex;
      flex-direction: column;
      font-weight: 500;

      @media (max-width: $container-sm-max-width) {
        display: block;

        .duration {
          margin-right: 0.84rem;
        }
      }
    }

    .almostSoldOut {
      @include font($size: rem(14), $weight: 500, $color: $white, $line-height: rem(18));

      display: block;
      margin-top: rem(12);
      margin-bottom: rem(8);
    }

    .fullCruiseBtn {
      width: auto;
      margin-top: rem(25);

      .cruiseBtn {
        @media (max-width: $container-lg1-width) {
          width: 100%;
        }
      }

      @media (max-width: $container-lg1-width) {
        margin-top: 1.25rem;
      }
    }

    .PackagePrice {
      display: none;

      .priceTotal {
        @include font($size: rem(30), $weight: 'bold', $line-height: rem(36), $color: $white);
      }

      .price-with-discount {
        .amount {
          @include font($size: rem(30), $weight: 'bold', $line-height: rem(36), $color: $white);
        }

        .original-amount {
          @include font($size: rem(17), $weight: 'normal', $line-height: rem(25), $color: $white);
        }
      }

      @media (max-width: $container-sm-max-width) {
        display: block;
      }
    }

    @media (max-width: $container-sm-max-width) {
      text-align: center;
    }

    @media (min-width: $container-sm-width) {
      min-height: $mdPackageCardMinHeight;
    }

    @media (min-width: $container-lg1-min-width) {
      min-height: $lgPackageCardMinHeight;
    }
  }

  .mediaImg::after {
    content: ' ';

    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    background-color: $gray-204;
  }

  .ImageVideo {
    width: 100%;
    height: 100%;
    transition: opacity 1s ease-in-out;

    .VideoContainer {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $container-lg2-width) {
    flex: 0 0 68%;
  }

  @media (max-width: $container-lg1-width) {
    flex: 0 0 50%;
  }

  @media (min-width: $container-sm-width) {
    min-height: $mdPackageCardMinHeight;
  }

  @media (min-width: $container-lg1-min-width) {
    min-height: $lgPackageCardMinHeight;
  }
}
