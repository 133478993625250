@import '@/_styles/import';
.SailingCard {
  @include card-border-radius();
  @include card-box-shadow();

  cursor: pointer;

  position: relative;

  display: grid;
  grid-template-areas:
    'year fromLabel'
    'date price';
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  justify-items: start;

  width: 100%;
  padding: 0.5rem 0.75rem;

  text-align: left;
  text-decoration: none;

  background: $white;
  border-radius: 0.5rem;

  transition-duration: 0.5s;
  transition-property: background, color;

  .iconYear {
    @include font($size: rem(12), $weight: 'bold', $color: $lightgrey2, $line-height: rem(16));

    grid-area: year;
    align-self: start;

    .Icon {
      position: relative;
      top: -0.0625rem;

      width: 0.75rem;
      height: 0.75rem;

      fill: $gray-510;
    }
  }

  .bestRateText {
    @include font($size: rem(12), $weight: 'bold', $color: $best-rate-text, $line-height: rem(16));

    margin-left: 0.25rem;
  }

  .almostSoldOut {
    @include font($size: rem(12), $weight: 700, $line-height: rem(16));

    margin-right: rem(4);
  }

  .startEndDate {
    grid-area: date;
    grid-column: 1 / 3;

    .almostSoldOut {
      margin-right: rem(4);
    }

    @include font($size: rem(14), $weight: 'bold', $color: $grey-scale, $line-height: rem(16));
  }

  .priceFromLabel {
    @include font($size: rem(12), $color: $lightgrey2, $line-height: rem(16));

    grid-area: fromLabel;
  }

  .price {
    @include font($size: rem(12), $color: $lightgrey2, $line-height: rem(16), $weight: 500);

    grid-area: price;
  }

  .priceFromLabel,
  .price {
    justify-self: end;
  }

  .strikethroughEnabled {
    @include font($color: $red);
  }

  &:hover {
    text-decoration: none;
    background: $black-violet;

    .startEndDate,
    .bestRateText,
    .price,
    .priceFromLabel {
      color: $white;
    }

    .iconYear {
      color: $gray-207;

      .Icon {
        fill: $gray-410;
      }
    }
  }
}

.promoSailingCard {
  &:hover {
    background: var(--promo-color-1, $black-violet);
  }
}

.leadCabinType {
  margin-bottom: 0;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
  box-shadow: unset;
}

.leadSuite {
  position: relative;

  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;

  padding: rem(4) rem(14);

  font-size: rem(11);
  line-height: rem(16);
  text-align: center;

  @include card-box-shadow();

  vertical-align: middle;

  background-color: $lead-cabin-color;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
