@import '@/_styles/import';
.PackageCard {
  touch-action: pan-y;

  display: flex;
  flex-wrap: wrap;

  width: 100%;
  min-height: 410px;
  margin-bottom: 30px;

  color: $body-color;

  background: $storyblock-background;

  .more-dates-link {
    justify-content: center;

    width: fit-content;
    margin: 0 auto;

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: underline;
    text-transform: none;
    letter-spacing: normal;

    .Icon.FlyOut {
      width: 19.2px;
      height: 19.2px;

      svg {
        position: relative;
        top: -1.5px;
        left: 6px;
      }
    }
  }

  .more-dates-link:focus-visible,
  &.keyboardFocus:focus-visible {
    outline: 2px solid black;
  }

  &__PortMess {
    width: 100%;
    background: #d9f4f999;
  }

  &__Skeleton {
    background-color: $white;
    border-color: $lightwhite;
    box-shadow: 0 2px 3px 0 $body-color-black-opacaity;

    .mediaImg {
      filter: none;

      &::after {
        background-color: $lightwhite;
      }

      .loading-skeleton {
        display: block;
        height: 100%;
        z-index: 3;
      }
    }

    .sailingCards {
      margin-bottom: rem(30);

      .SailingCard {
        font-size: rem(10);
        border: 1px solid $lightwhite;
        box-shadow: none;
      }

      > .SailingCard {
        margin-bottom: rem(12);
      }

      > .SailingCard:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: $container-sm-max-width) {
    display: inline-block;
    width: 48%;
    height: auto;
    margin-bottom: 40px;
  }

  @media (max-width: $container-sm-width) {
    width: 65%;
    margin-bottom: 30px;
  }

  @media (max-width: $container-xs-width) {
    width: 100%;
  }

  @include package-card-border-radius();
  @include card-box-shadow();
}
